import {useTheme, Typography, Tooltip, Box} from '@mui/material';
import BatteryCharging90Icon from '@mui/icons-material/BatteryCharging90';
import {Icon, InsulinTrendIcon, ReservoirIcon} from '../../../components';
import {DeviceCardProps} from './types';
//import {IsMobile} from 'src/utils/IsMobile'; TODO: add mobile information

import {
  StyledContainer,
  StyledContent,
  StyledLastUpdate,
  StyledTimeValue,
  StyledValueContainer,
  StyledValue,
  StyledOperationMode,
  StyledIconButton,
} from './styles';
import moment from 'moment-timezone';

export const DeviceCard = ({
  cardHeading,
  showReservoirDetails,
  reservoirPercentage,
  showBatteryPercentage,
  batteryPercentage,
  lastUpdatedTimeTitle,
  lastUpdatedTime,
  showReadingValueAndUnit,
  showLastHoursInsulin,
  showLoopMode,
  readingValue,
  secondReadingValue,
  readingUnit,
  readingTrend,
  readingMode,
  isDeviceCard,
  tooltipText,
  showLastCarboMeal,
}: DeviceCardProps): JSX.Element => {
  const theme = useTheme();

  const dateTimeAgo = lastUpdatedTime && moment().diff(lastUpdatedTime, 'minutes') > 5;
  const dateTimestamp =
    lastUpdatedTime && moment().isSame(moment(lastUpdatedTime), 'day')
      ? moment(lastUpdatedTime).format('HH:mm')
      : moment(lastUpdatedTime).format('HH:mm (YYYY-MM-DD)');

  const loopColor =
    {
      O: '#EA9E8D',
      C: '#77ACA2',
    }[readingMode?.charAt(0) ?? ''] ?? theme.palette.primaryCustom[900];

  return (
    <StyledContainer>
      <StyledValueContainer sx={{width: '100%', justifyContent: 'space-between', alignItems: 'flex-start'}}>
        <StyledLastUpdate>
          <StyledOperationMode sx={{alignItems: 'flex-start'}}>
            <Typography sx={{fontSize: '12px', fontWeight: '600', color: `${theme.palette.basic[900]}`}}>
              {cardHeading}
            </Typography>
            <Tooltip title={tooltipText}>
              <StyledIconButton style={{marginTop: '1px'}}>
                <Icon icon="info" size={/*IsMobile() ? 12 :*/ 16} color={theme.palette.basic[500]} />
              </StyledIconButton>
            </Tooltip>
          </StyledOperationMode>
          {showLastCarboMeal && (
            <StyledTimeValue>
              <Typography variant="bodyBig" color={theme.palette.basic[900]}>
                {dateTimestamp}
              </Typography>
            </StyledTimeValue>
          )}
        </StyledLastUpdate>
        {isDeviceCard && (
          <Box sx={{display: 'flex', flexDirection: 'column', ml: '46px', alignItems: 'center', flexWrap: 'wrap'}}>
            {showReservoirDetails && (
              <StyledOperationMode>
                <Box sx={{width: '20px', height: '20px'}}>
                  <ReservoirIcon />
                </Box>
                <Typography
                  sx={{
                    fontSize: '12px',
                    color: '#535B62',
                    mt: '3.5px',
                    mr: '8px',
                    ml: '2px',
                    alignItems: 'flex-start',
                  }}>
                  {(reservoirPercentage ?? NaN) * 100}% Insulin Reservoir Level
                </Typography>
              </StyledOperationMode>
            )}
            {showBatteryPercentage && (
              <StyledOperationMode>
                <BatteryCharging90Icon sx={{transform: 'rotate(-90deg)'}} />
                <Typography
                  sx={{
                    fontSize: '12px',
                    color: '#535B62',
                    mt: '3.5px',
                    mr: '8px',
                    ml: '2px',
                    alignItems: 'flex-start',
                  }}>
                  {(batteryPercentage ?? NaN) * 100}% Insulin Pump Battery
                </Typography>
              </StyledOperationMode>
            )}
            {showLoopMode && (
              <StyledOperationMode>
                <Typography sx={{fontSize: '1rem', fontWeight: '700', color: loopColor, height: '1.8rem'}}>
                  {readingMode?.replace(/_/g, ' ')}
                </Typography>
              </StyledOperationMode>
            )}
            {/* TODO: adding logic
              {!IsMobile() && (
              <Chip
                label="CONNECTED"
                size={'small'}
                style={{backgroundColor: 'rgb(136, 144, 158)', color: '#fafafa'}}
              />
            )} */}
          </Box>
        )}
        {showLastCarboMeal && (
          <StyledValueContainer sx={{flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
            <StyledValue style={{marginTop: '20px', marginRight: '2rem'}}>
              <Typography
                sx={{
                  fontSize: '1.4rem',
                  fontWeight: '700',
                  color: `${theme.palette.basic[900]}`,
                  height: '1.8rem',
                }}>
                {readingValue?.toFixed(2)}
              </Typography>
              <Typography sx={{fontSize: '13px', fontWeight: '600', color: '#535B62'}}>U</Typography>
            </StyledValue>
          </StyledValueContainer>
        )}
      </StyledValueContainer>
      <StyledContent>
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <StyledLastUpdate>
            <Typography
              variant="bodySmall"
              fontWeight={showLastCarboMeal ? '600' : '400'}
              color={showLastCarboMeal ? theme.palette.primaryCustom[900] : theme.palette.customColors?.lightGrey}>
              {lastUpdatedTimeTitle}
            </Typography>
            {!showLastHoursInsulin && (
              <StyledTimeValue>
                {!showLastCarboMeal && (
                  <Icon
                    icon="check-rounded"
                    size={20}
                    color={dateTimeAgo ? 'gray' : theme.palette.primaryCustom[800]}
                  />
                )}
                <Typography variant="bodyBig" color={theme.palette.basic[900]}>
                  {dateTimestamp}
                </Typography>
              </StyledTimeValue>
            )}
          </StyledLastUpdate>
          {showReadingValueAndUnit && (
            <StyledValueContainer>
              <StyledValue>
                <Typography
                  sx={{fontSize: '1.4rem', fontWeight: '700', color: `${theme.palette.basic[900]}`, height: '1.8rem'}}>
                  {readingValue}
                </Typography>
                <Typography sx={{fontSize: '13px', fontWeight: '600', color: '#535B62'}}>{readingUnit}</Typography>
                {readingTrend && <InsulinTrendIcon trend={readingTrend} size={12} />}
              </StyledValue>
            </StyledValueContainer>
          )}
          {showLastHoursInsulin && (
            <StyledValueContainer>
              <StyledValue style={{marginTop: '20px', marginRight: '2rem'}}>
                <Typography
                  sx={{fontSize: '1.4rem', fontWeight: '700', color: `${theme.palette.basic[900]}`, height: '1.8rem'}}>
                  {readingValue?.toFixed(2)}
                </Typography>
                <Typography sx={{fontSize: '13px', fontWeight: '600', color: '#535B62'}}>U</Typography>
              </StyledValue>
            </StyledValueContainer>
          )}
          {showLastCarboMeal && (
            <StyledValueContainer>
              <StyledValue style={{marginTop: '20px', marginRight: '2rem'}}>
                <Typography
                  sx={{fontSize: '1.4rem', fontWeight: '700', color: `${theme.palette.basic[900]}`, height: '1.8rem'}}>
                  {secondReadingValue}
                </Typography>
                <Typography sx={{fontSize: '13px', fontWeight: '600', color: '#535B62'}}>g</Typography>
              </StyledValue>
            </StyledValueContainer>
          )}
        </Box>
      </StyledContent>
    </StyledContainer>
  );
};
