import {useTheme, Typography, Box, Grid, Divider} from '@mui/material';
import {CGMMeasurements, StaticData} from '../../../api';
import BasalChart from './BasalChart';
import CarbohydrateRatiosChart from './CarbohydrateRatiosChart';
import InsulinSensitivityChart from './InsulinSensitivityChart';

interface TreatmentSectionProps {
  staticData: StaticData;
  cgmMeasurements?: CGMMeasurements[];
}

export const TreatmentSection = ({staticData}: TreatmentSectionProps): JSX.Element => {
  const theme = useTheme();
  const {basalProfiles, insulinSensitivity, carbohydrateRatios, totalDailyInsulin, a1c} = staticData;

  return (
    <Box sx={{boxShadow: 1, borderRadius: 2, padding: 2, background: theme.palette.basic[0]}}>
      <Typography variant="h3" color={theme.palette.basic[1000]}>
        Treatment
      </Typography>
      <Grid container spacing={2} mt={1} mb={1}>
        <Grid item xs={12} lg={6}>
          <Typography variant="body1" color={theme.palette.customColors?.lightGrey}>
            Last glycated hemoglobin (HbA1c) test (%)
          </Typography>
          <Typography variant="h3" color={theme.palette.basic[1000]}>
            {a1c}%
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <Typography variant="h4" color={theme.palette.basic[1000]} marginTop={3}>
        Insulin
      </Typography>
      <Grid container spacing={2} mt={0.5} mb={1}>
        <Grid item xs={12} lg={4}>
          <Typography variant="body1" color={theme.palette.customColors?.lightGrey}>
            Total daily insulin (TDI)
          </Typography>
          <Typography variant="h3" color={theme.palette.basic[1000]}>
            {totalDailyInsulin} U
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={0.5} mb={1}>
        {basalProfiles[0] && (
          <Grid item xs={12} style={{overflowX: 'auto'}}>
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
              <Typography variant="h4" color={theme.palette.basic[1000]} marginTop={3}>
                Open-loop basal profile
              </Typography>
              <BasalChart {...basalProfiles} />
            </Box>
          </Grid>
        )}
        {carbohydrateRatios && (
          <Grid item xs={12} style={{overflowX: 'auto'}}>
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
              <Typography variant="h4" color={theme.palette.basic[1000]} marginTop={3}>
                Carbohydrate to insulin ratio (CR) profile
              </Typography>
              <CarbohydrateRatiosChart {...carbohydrateRatios} />
            </Box>
          </Grid>
        )}
        {insulinSensitivity && (
          <Grid item xs={12} style={{overflowX: 'auto'}}>
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
              <Typography variant="h4" color={theme.palette.basic[1000]} marginTop={3}>
                Correction factor (CF) profile
              </Typography>
              <InsulinSensitivityChart {...insulinSensitivity} />
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
