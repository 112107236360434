import {useTheme, Typography, Box, Divider, Grid, Tooltip} from '@mui/material';
import {DeviceCard} from '../../../components';
import {GeneralSectionProps} from './types';
import {StyledContainer} from './styles';
import {GraphsWrapper} from '../../../sections/participant/general/GraphsWrapper';
import {mmolPerLToMgPerdL} from '../../../helpers';
import {useAppSelector} from '../../../store';
import {unitSelector} from '../../../store/reducers/unitSlice';
import {pollingInterval} from '../../../constants/api';
import {measurementsService, participantsService} from '../../../api/services';
import {useCallback, useEffect, useRef, useMemo, useState} from 'react';
import {faUserXmark} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

export const GeneralSection = ({generalRef, dynamicData, staticData}: GeneralSectionProps) => {
  const theme = useTheme();
  const {unit} = useAppSelector(unitSelector);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const {id, basalProfiles} = staticData;
  const {devices, mealEvents, boluses} = dynamicData;

  const {
    data: latestCgmMeasurements,
    isUninitialized: isLatestCgmMeasurementsUninitialized,
    refetch: refetchLatestCgmMeasurements,
  } = measurementsService.useGetLatestCgmMeasurementsQuery(undefined, {
    pollingInterval,
  });

  const {
    data: dynamicDataLastHours,
    isUninitialized: isDynamicDataLastHoursUnitialized,
    refetch: refetchDynamicDataLastHours,
  } = participantsService.useGetDynamicScreenDataQuery({
    params: {id: `${id}?timeframe=3h`},
  });

  const refetchAllData = useCallback(() => {
    if (!isLatestCgmMeasurementsUninitialized) {
      refetchLatestCgmMeasurements();
    }
    if (!isDynamicDataLastHoursUnitialized) {
      refetchDynamicDataLastHours();
    }
  }, [
    isLatestCgmMeasurementsUninitialized,
    refetchLatestCgmMeasurements,
    isDynamicDataLastHoursUnitialized,
    refetchDynamicDataLastHours,
  ]);

  useEffect(() => {
    const handleInterval = () => {
      refetchAllData();
    };

    intervalRef.current = setInterval(handleInterval, 300000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const latestCgmMeasurement =
    latestCgmMeasurements &&
    latestCgmMeasurements.find((measurement: {participantId: number}) => measurement.participantId === id);

  const cgmMeasurementsValue = mmolPerLToMgPerdL(latestCgmMeasurement?.measurement ?? 0, unit);

  const latestLoopMode = latestCgmMeasurement?.loopMode ?? '--';

  const twoHoursAgo = new Date(Date.now() - 2 * 60 * 60 * 1000);

  const LastHoursInsulinTotal = useMemo(() => {
    if (!dynamicDataLastHours) return null;

    let total = 0;

    dynamicDataLastHours.boluses.forEach(item => {
      if (new Date(item.eventUTCInstant) >= twoHoursAgo) {
        total += item?.mealBolusPassedSafetyNet
          ? item?.mealBolusSafetyNetAmount ?? 0
          : item?.mealBolusComputedAmount ?? 0;
        total += item?.manualBolusPassedSafetyNet
          ? item?.manualBolusSafetyNetAmount ?? 0
          : item?.manualBolusComputedAmount ?? 0;
        total += item?.correctionBolusPassedSafetyNet
          ? item?.correctionBolusSafetyNetAmount ?? 0
          : item?.correctionBolusComputedAmount ?? 0;
      }
    });

    dynamicDataLastHours.microboluses.forEach(item => {
      if (new Date(item.eventUTCInstant) >= twoHoursAgo) {
        total += item?.basalPassedSafetyNet ? item?.basalSafetyNetAmount ?? 0 : item?.basalComputedAmount ?? 0;
      }
    });
    return total;
  }, [dynamicDataLastHours, twoHoursAgo]);

  // Temporary solution until endpoint is ready
  const [lastMeal, setLastMeal] = useState(() => {
    if (mealEvents && boluses) {
      const bolus = dynamicDataLastHours?.boluses?.find(
        bolus => bolus.eventUTCInstant === dynamicData?.mealEvents[0]?.eventUTCInstant,
      );
      return {
        timestamp: mealEvents[0].eventUTCInstant,
        amount: mealEvents[0].carbohydratesEstimate,
        value: bolus?.mealBolusPassedSafetyNet
          ? bolus?.mealBolusSafetyNetAmount ?? 0
          : bolus?.mealBolusComputedAmount ?? 0,
      };
    }
    return {timestamp: '--', amount: NaN, value: NaN};
  });

  useEffect(() => {
    if (dynamicDataLastHours?.mealEvents[0] != null) {
      const bolus = dynamicDataLastHours?.boluses?.find(
        bolus => bolus.eventUTCInstant === dynamicData?.mealEvents[0]?.eventUTCInstant,
      );
      setLastMeal(prev => ({
        ...prev,
        timeStamp: dynamicDataLastHours?.mealEvents[0].eventUTCInstant,
        amount: dynamicDataLastHours?.mealEvents[0].carbohydratesEstimate,
        value: bolus?.mealBolusPassedSafetyNet
          ? bolus?.mealBolusSafetyNetAmount ?? 0
          : bolus?.mealBolusComputedAmount ?? 0,
      }));
    }
  }, [dynamicDataLastHours]);

  return (
    <StyledContainer ref={generalRef}>
      <Box sx={{boxShadow: 1, borderRadius: 2, padding: 2, background: theme.palette.basic[0]}}>
        {staticData && (
          <Box sx={{display: 'flex', flexDirection: 'column', mb: '7px'}}>
            <Typography sx={{color: '#9099AA', fontSize: '13px'}}>#{staticData.id}</Typography>
            <Box sx={{display: 'flex', flexDirection: 'row'}}>
              <Typography sx={{color: '#2B2C2E', fontSize: '20px', fontWeight: '600', marginRight: '16px'}}>
                {staticData.name}
                <Tooltip title="Test Participant" arrow>
                  <FontAwesomeIcon
                    icon={faUserXmark as IconProp}
                    size="lg"
                    color="red"
                    style={{marginLeft: '8px', cursor: 'pointer'}}
                  />
                </Tooltip>
                {/* <Tooltip title="Real Participant" arrow>
                  <FontAwesomeIcon
                    icon={faUserCheck as IconProp}
                    size="lg"
                    color="#059984"
                    style={{marginLeft: '8px', cursor: 'pointer'}}
                  />
                </Tooltip> */}
              </Typography>
            </Box>
          </Box>
        )}
        <Divider />
        <Grid container xs={10} lg={12} item spacing={2} mt={0.5} mb={1}>
          {devices[2] && latestCgmMeasurement && (
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <DeviceCard
                cardHeading="Glucose Monitor"
                showReservoirDetails={false}
                showBatteryPercentage={false}
                lastUpdatedTimeTitle="Last update:"
                lastUpdatedTime={latestCgmMeasurement.eventUTCInstant}
                showReadingValueAndUnit={true}
                readingValue={cgmMeasurementsValue}
                readingUnit={unit}
                readingTrend={latestCgmMeasurement.trend}
                tooltipText="Glycemia read by the CGM sensor, current glucose rate of change, and control mode"
                isDeviceCard={true}
                readingMode={latestLoopMode}
                showLoopMode={true}
              />
            </Grid>
          )}
          {devices[1] && LastHoursInsulinTotal && (
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <DeviceCard
                cardHeading="Insulin Pump"
                showReservoirDetails={true}
                reservoirPercentage={devices[1].pumpInsulinPercentage}
                showBatteryPercentage={true}
                batteryPercentage={devices[1].batteryPercentage}
                lastUpdatedTimeTitle="Insulin infused in the last 2h:"
                lastUpdatedTime={''}
                showLastHoursInsulin={true}
                readingValue={LastHoursInsulinTotal}
                isDeviceCard={true}
                tooltipText="Sum of basal, automatic correction boluses, meal boluses, and manual correction boluses in the past 2 hours"
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <DeviceCard
              cardHeading="Last communicated meal"
              showReservoirDetails={false}
              lastUpdatedTimeTitle="Coresponding meal bolus "
              readingValue={lastMeal.value}
              secondReadingValue={lastMeal.amount}
              lastUpdatedTime={lastMeal.timestamp}
              showLastCarboMeal={true}
              tooltipText="Carbohydrate content of the last communicated meal to the app by the user"
            />
          </Grid>
        </Grid>
        <GraphsWrapper {...basalProfiles} />
      </Box>
    </StyledContainer>
  );
};
