import {DynamicData} from '../../../api';
import {useRef, useEffect, useState, useMemo} from 'react';
import {scaleTime, scaleLinear} from '@visx/scale';
import {AxisBottom} from '@visx/axis';
import {Group} from '@visx/group';
import {timeSampConvert} from '../utils';
import correctionBolusIcon from './assets/correctionBolus.svg';
import carbohydratesIcon from './assets/carbohydrates.svg';
import mealBolusIcon from './assets/mealBolus.svg';
import hypoTreatmentIcon from './assets/hypoTreatment.svg';
import {useAppSelector} from '../../../store';
import {unitSelector} from '../../../store/reducers/unitSlice';
import {Grid} from '@mui/material';
import GridColumns from '@visx/grid/lib/grids/GridColumns';
import GridRows from '@visx/grid/lib/grids/GridRows';

interface DataPoint {
  x: string;
  y: number;
  yAmount: number;
  isUnitU: boolean;
  icon: string;
  label: string;
  loopMode?: string;
}

interface BolusAndTreatmentChartProps {
  boluses: DynamicData['boluses'];
  mealEvents: DynamicData['mealEvents'];
  cgmMeasurements: DynamicData['cgmMeasurements'];
  crosshairTime: number | null;
  selectedDateRange: string;
  setCrosshairTime: (time: number | null) => void;
}

export const BolusAndTreatmentChart = ({
  boluses,
  mealEvents,
  crosshairTime,
  cgmMeasurements,
  setCrosshairTime,
  selectedDateRange,
}: BolusAndTreatmentChartProps): JSX.Element => {
  const svgRef = useRef<SVGSVGElement>(null);
  const [width, setWidth] = useState(1000);
  const [tooltip, setTooltip] = useState<{x: number; y: number; loopMode: string | undefined}>({
    x: 0,
    y: 0,
    loopMode: undefined,
  });
  const [iconTooltip, setIconTooltip] = useState<{
    x: number;
    y: number;
    yAmount?: number;
    xFormatted?: string;
    label?: string;
    isUnitU: boolean;
  }>({
    x: 0,
    y: 0,
    yAmount: 0,
    xFormatted: '',
    label: '',
    isUnitU: false,
  });

  const height = 250;
  const margin = {top: 20, right: 20, bottom: 30, left: 40};
  const minY = 0;
  const maxY = 200;

  const loopModeColors = {
    CLOSED_LOOP: '#77ACA2',
    OPEN_LOOP: '#EA9E8D',
    undefined: '#C1C1C1',
  };

  // mealEvents
  const carbohydrates: DataPoint[] = mealEvents
    .filter(event => !event.isHypoTreatment)
    .map(event => ({
      x: timeSampConvert(event.eventUTCInstant),
      y: 150,
      yAmount: event.carbohydratesEstimate,
      icon: carbohydratesIcon,
      label: 'Carbohydrates',
      isUnitU: false,
      loopMode: event.loopMode,
    }));
  const correctionBoluses: DataPoint[] = mealEvents
    .filter(event => event.isHypoTreatment)
    .map(event => ({
      x: timeSampConvert(event.eventUTCInstant),
      y: 150,
      yAmount: event.carbohydratesEstimate,
      icon: hypoTreatmentIcon,
      label: 'Hypo Treatment',
      isUnitU: false,
      loopMode: event.loopMode,
    }));

  // boluses
  const mealBolus: DataPoint[] = boluses
    .filter(bolus => bolus.mealBolusSafetyNetAmount !== null)
    .map(bolus => ({
      x: timeSampConvert(bolus.eventUTCInstant),
      y: 50,
      yAmount: bolus.mealBolusSafetyNetAmount!,
      icon: mealBolusIcon,
      label: 'Meal Bolus',
      isUnitU: true,
      backgroundColor: '#FFFFFF',
    }));
  const prandialBoluses: DataPoint[] = boluses
    .filter(bolus => bolus.correctionBolusSafetyNetAmount !== null)
    .map(bolus => ({
      x: timeSampConvert(bolus.eventUTCInstant),
      y: 50,
      yAmount: bolus.correctionBolusSafetyNetAmount!,
      icon: correctionBolusIcon,
      label: 'Correction Bolus',
      isUnitU: true,
      backgroundColor: '#FFFFFF',
    }));

  const dataPoints: DataPoint[] = [...carbohydrates, ...correctionBoluses, ...mealBolus, ...prandialBoluses];
  const {unit} = useAppSelector(unitSelector);

  const cgmDataCombined = useMemo(
    () =>
      cgmMeasurements.map(cgm => ({
        x: timeSampConvert(cgm.eventUTCInstant),
        loopMode: cgm.loopMode,
      })),
    [cgmMeasurements, unit],
  );

  const minX = useMemo(() => Math.min(...cgmDataCombined.map(d => new Date(d.x).getTime())), [cgmDataCombined]);
  const maxX = useMemo(() => Math.max(...cgmDataCombined.map(d => new Date(d.x).getTime())), [cgmDataCombined]);

  useEffect(() => {
    if (svgRef.current) {
      setWidth(svgRef.current.clientWidth);
    }

    const handleResize = () => {
      if (svgRef.current) {
        setWidth(svgRef.current.clientWidth);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const xScale = scaleTime({
    domain: [minX, maxX],
    range: [margin.left, width - margin.right],
  });
  const yScale = scaleLinear({
    domain: [minY, maxY],
    range: [height - margin.bottom, margin.top],
  });

  const handleMouseMove = (event: React.MouseEvent<SVGRectElement, MouseEvent>) => {
    const {clientX} = event;
    const svgRect = svgRef.current?.getBoundingClientRect();
    if (!svgRect) return;

    const mouseXPosition = clientX - svgRect.left;
    const timeAtMousePosition = xScale.invert(mouseXPosition).getTime();
    setCrosshairTime(timeAtMousePosition);
  };

  const handleMouseLeave = () => {
    setCrosshairTime(null);
    setTooltip({x: 0, y: 0, loopMode: undefined}); // loopMode tooltip
  };

  const renderContinuousLoopModeLine = (cgmData: typeof cgmDataCombined, xScale: any) => {
    return (
      <g>
        {cgmData.map((cgm, index) => {
          const startX = xScale(new Date(cgm.x));
          const nextCgm = cgmData[index + 1];
          if (!nextCgm) return null;

          const endX = xScale(new Date(nextCgm.x));
          const loopModeColor = loopModeColors[cgm.loopMode as keyof typeof loopModeColors];

          return (
            <line
              key={`loopmode-line-${index}`}
              x1={startX}
              y1={20}
              x2={endX}
              y2={20}
              stroke={loopModeColor}
              strokeWidth={10}
              onMouseEnter={event => {
                const svgRect = svgRef.current?.getBoundingClientRect();
                if (!svgRect) return;
                setTooltip({
                  x: event.clientX - svgRect.left,
                  y: 15,
                  loopMode: cgm.loopMode,
                });
              }}
              onMouseLeave={handleMouseLeave}
            />
          );
        })}
      </g>
    );
  };

  const isLgDateRange =
    selectedDateRange === 'week' ||
    selectedDateRange === 'two-weeks' ||
    selectedDateRange === 'month' ||
    selectedDateRange === 'all';
  const iconsSize = isLgDateRange ? 25 : 50;
  // const labelsSize = isLgDateRange ? 8 : 11;

  const renderIconsAndLabels = (data: DataPoint[], xZoomScale: any, yZoomScale: any) =>
    data.map((d, i) => (
      <g key={`item-${i}`}>
        <image
          href={d.icon}
          x={xZoomScale(new Date(d.x)) - iconsSize / 2}
          y={yZoomScale(d.y) - iconsSize / 2}
          width={iconsSize}
          height={iconsSize}
          onMouseEnter={() => {
            const svgRect = svgRef.current?.getBoundingClientRect();
            if (!svgRect) return;
            setIconTooltip({
              x: xZoomScale(new Date(d.x)),
              y: yZoomScale(d.y) - iconsSize / 2 - 15,
              xFormatted: new Date(d.x).toLocaleTimeString('en-EU', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
              }),
              label: d.label,
              yAmount: d.yAmount,
              isUnitU: d.isUnitU,
            });
          }}
          onMouseLeave={() => setIconTooltip({x: 0, y: 0, xFormatted: '', label: '', yAmount: 0, isUnitU: false})}
        />
        {/* <text
          x={xZoomScale(new Date(d.x))}
          y={yZoomScale(d.y) + iconsSize / 2 + 10}
          textAnchor="middle"
          fontSize={labelsSize}
          fill="black"
          pointerEvents="none">
          {d.isUnitU ? d.yAmount.toFixed(2) + ' U' : d.yAmount.toFixed(0) + ' g'}
        </text> */}
      </g>
    ));

  const crosshairXPosition = crosshairTime ? xScale(new Date(crosshairTime)) : null;

  return (
    <Grid container xs={12} spacing={2} mt={2}>
      <Grid xs={0.5} display={'flex'}></Grid>
      <Grid xs={11.5}>
        <svg ref={svgRef} width="100%" height={height}>
          <Group>
            {/* LoopMode*/}
            {renderContinuousLoopModeLine(cgmDataCombined, xScale)}
            {/* LoopMode Tooltip */}
            {tooltip.loopMode && (
              <text x={tooltip.x} y={tooltip.y} fontSize={12} fill="black" textAnchor="middle" pointerEvents="none">
                {tooltip.loopMode === 'CLOSED_LOOP' ? 'Closed Loop' : 'Open Loop'}
              </text>
            )}
            {/* Grid */}
            <GridColumns
              scale={xScale}
              height={height - margin.bottom}
              stroke="#e0e0e0"
              strokeDasharray="5,5"
              pointerEvents="none"
            />
            <GridRows scale={yScale} width={width - margin.right} strokeDasharray="2,2" top={margin.top} />
            <AxisBottom
              top={height - margin.bottom}
              scale={xScale}
              tickClassName="axis-tick"
              tickFormat={d => {
                const date = d instanceof Date ? d : new Date(d.valueOf());
                return date.toLocaleTimeString('en-EU', {
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: false,
                });
              }}
              tickLabelProps={() => ({
                fill: 'black',
                fontSize: 11,
                textAnchor: 'middle',
              })}
            />
            <rect
              x={margin.left}
              y={margin.top}
              width={width - margin.left - margin.right}
              height={height - margin.top - margin.bottom}
              fill={'transparent'}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
              pointerEvents="all"
            />
            {/* Icons and Labels */}
            {renderIconsAndLabels(dataPoints, xScale, yScale)}
            {iconTooltip.xFormatted && iconTooltip.label && (
              <foreignObject
                x={iconTooltip.x + 50}
                y={iconTooltip.y - 40}
                width={200}
                height={180}
                pointerEvents="none">
                <div
                  style={{
                    position: 'absolute',
                    backgroundColor: '#fff',
                    padding: '5px 10px',
                    borderRadius: '5px',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
                    justifyContent: 'center',
                    textAlign: 'center',
                    zIndex: 9999,
                  }}>
                  <h5>{iconTooltip.label}</h5>
                  <p style={{fontWeight: 'bold', fontSize: '16px'}}>
                    {(() => {
                      if (iconTooltip.yAmount === undefined) {
                        return 'N/A';
                      }

                      if (iconTooltip.isUnitU) {
                        return `${iconTooltip.yAmount.toFixed(2)} U`;
                      }

                      return `${iconTooltip.yAmount.toFixed(0)} g`;
                    })()}
                  </p>
                  <p style={{fontSize: '14px'}}>{iconTooltip.xFormatted}</p>
                </div>
              </foreignObject>
            )}

            {/* Crosshair */}
            {crosshairXPosition !== null && (
              <line
                x1={crosshairXPosition}
                x2={crosshairXPosition}
                y1={margin.top}
                y2={height - margin.bottom}
                stroke="gray"
                strokeDasharray="4,4"
              />
            )}
          </Group>
        </svg>
      </Grid>
    </Grid>
  );
};
