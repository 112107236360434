import {useState} from 'react';
import {isNil} from 'lodash';
import {Table, TableBody, TableContainer, Tooltip, Typography, useTheme} from '@mui/material';

import {InlineLink, InsulinTrendIcon, Search, Tag} from '../../../components';
import {TableHead, TableHeadCell} from '../../../components/table-head';
import {TableNavigationPanel} from '../../../components/table-navigation-panel';
import type {Participant, ClinicalTrial, Measurement} from '../../../api/service-types';
import {getClinicalTrialStatus} from '../../../helpers';
import {unitSelector} from '../../../store/reducers/unitSlice';
import {useAppSelector} from '../../../store';
import {useSearch} from '../../../hooks';
import {changeFormateDate, changeFormateDateUTC} from '../../../utils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {
  SearchStyledContainer,
  StyledBodyTableCell,
  StyledBodyTableRow,
  StyledCgmContainer,
  StyledContainer,
} from './styles';
import {faUserXmark} from '@fortawesome/free-solid-svg-icons';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

const defaultPage = 1;
const defaultRowsPerPage = 10;
const cells: TableHeadCell[] = [
  {label: 'ID', width: '5%'},
  {label: 'Participant name', width: '15%'},
  {label: 'Participant Type', width: '10%'},
  {label: 'Status', width: '10%'},
  {label: 'Trial', width: '10%'},
  {label: 'Last CGM reading', width: '20%', align: 'center'},
  {label: 'Last update', width: '20%'},
  {label: 'Last update Local', width: '20%'},
];

type ParticipantsTableProps = {
  participants: Participant[];
  clinicalTrials: ClinicalTrial[];
  latestCgmMeasurements: Measurement[];
};

export const ParticipantsTable = ({
  participants,
  clinicalTrials,
  latestCgmMeasurements,
}: ParticipantsTableProps): JSX.Element => {
  const theme = useTheme();

  const {unit} = useAppSelector(unitSelector);

  const [filteredParticipants, setFilteredParticipants] = useState(participants);
  const {searchValue, onChangeSearchValue, onResetSearchValue} = useSearch();
  const [page, setPage] = useState(defaultPage);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

  const onReset = () => {
    onResetSearchValue();
    setFilteredParticipants(participants);
    setPage(defaultPage);
  };

  const onSearchParticipantsByNameOrClinicalTrialId = () => {
    const nextParticipants = participants.filter(participant => {
      const searchValueToLowerCase = searchValue.toLowerCase();
      const nameToLowerCase = participant.name.toLowerCase();
      const clinicalTrialIdToString = String(participant.clinicalTrial.id);

      if (nameToLowerCase.includes(searchValueToLowerCase) || clinicalTrialIdToString.includes(searchValue)) {
        return participant;
      }

      return null;
    });

    setFilteredParticipants(nextParticipants);
    setPage(defaultPage);
  };

  const participantsByPage = filteredParticipants.slice(
    (page - 1) * rowsPerPage,
    (page - 1) * rowsPerPage + rowsPerPage,
  );

  const hasFoundParticipants = filteredParticipants.length > 0;

  return (
    <StyledContainer>
      <SearchStyledContainer>
        <Search
          value={searchValue}
          onChange={onChangeSearchValue}
          onSearch={onSearchParticipantsByNameOrClinicalTrialId}
          onReset={onReset}
        />
      </SearchStyledContainer>
      {hasFoundParticipants ? (
        <TableContainer>
          <Table>
            <TableHead cells={cells} />
            <TableBody>
              {participantsByPage.map(participant => {
                const clinicalTrial = clinicalTrials.find(
                  clinicalTrial => clinicalTrial.id === participant.clinicalTrial.id,
                );
                if (!clinicalTrial) throw new Error('clinicalTrial is undefined, unhandled state');
                const clinicalTrialStatus = getClinicalTrialStatus(clinicalTrial);
                const latestCgmMeasurement = latestCgmMeasurements.find(
                  measurement => measurement.participantId === participant.id,
                );
                const measurementValue = latestCgmMeasurement?.measurement ?? 0;

                return (
                  <StyledBodyTableRow key={participant.id} hover>
                    <StyledBodyTableCell>
                      <Typography variant="bodyBig" color={theme.palette.basic[1000]}>
                        #{participant.id}
                      </Typography>
                    </StyledBodyTableCell>
                    <StyledBodyTableCell>
                      <InlineLink to={`/dashboard/participants/${participant.id}`}>
                        <span style={{display: 'inline-flex', alignItems: 'center'}}>{participant.name}</span>
                      </InlineLink>
                    </StyledBodyTableCell>
                    <StyledBodyTableCell>
                      <Tooltip title="Test Participant" arrow>
                        <FontAwesomeIcon
                          icon={faUserXmark as IconProp}
                          size="lg"
                          color="red"
                          style={{marginLeft: '8px', cursor: 'pointer'}}
                        />
                      </Tooltip>

                      {/* <Tooltip title="Real Participant" arrow>
                        <FontAwesomeIcon
                          icon={faUserCheck as IconProp}
                          size="lg"
                          color="#059984"
                          style={{marginLeft: '8px', cursor: 'pointer'}}
                        />
                      </Tooltip> */}
                    </StyledBodyTableCell>
                    <StyledBodyTableCell>
                      <Tag marginval="none" status={clinicalTrialStatus} />
                    </StyledBodyTableCell>
                    <StyledBodyTableCell>
                      <Typography variant="bodyBig" color={theme.palette.basic[1000]}>
                        Clinical trial #{participant.clinicalTrial.id}
                      </Typography>
                    </StyledBodyTableCell>
                    <StyledBodyTableCell align="center">
                      <StyledCgmContainer>
                        <Typography variant="bodyBig" color={theme.palette.basic[1000]}>
                          {`${measurementValue} ${unit}`}
                        </Typography>
                        {!isNil(latestCgmMeasurement) && (
                          <InsulinTrendIcon trend={latestCgmMeasurement.trend} size={16} />
                        )}
                      </StyledCgmContainer>
                    </StyledBodyTableCell>
                    <StyledBodyTableCell>
                      <Typography variant="bodyBig" color={theme.palette.basic[1000]}>
                        {latestCgmMeasurement?.eventUTCInstant
                          ? changeFormateDateUTC(latestCgmMeasurement.eventUTCInstant)
                          : 'no updates'}
                      </Typography>
                    </StyledBodyTableCell>
                    <StyledBodyTableCell>
                      <Typography variant="bodyBig" color={theme.palette.basic[1000]}>
                        {latestCgmMeasurement?.eventLocalDateTime
                          ? changeFormateDate(latestCgmMeasurement.eventLocalDateTime)
                          : 'no updates'}
                      </Typography>
                    </StyledBodyTableCell>
                  </StyledBodyTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        /* TODO: This is a temporary solution. We have to discuss and choose some solution */
        <div>Nothing found</div>
      )}
      <TableNavigationPanel
        page={page}
        rowsNumber={filteredParticipants.length}
        rowsPerPage={rowsPerPage}
        onChangePage={setPage}
        onChangeRowsPerPage={setRowsPerPage}
      />
    </StyledContainer>
  );
};
