import type {MeasurementTrend} from '../../api/service-types';
import {StyledContainer} from './styles';
import iconRedUp from '../../assets/images/redUP.svg';
import iconRedDown from '../../assets/images/redDOWN.svg';
import iconOrangeUp from '../../assets/images/orangeUP.svg';
import iconOrangeDown from '../../assets/images/orangeDOWN.svg';
import iconYellowUp from '../../assets/images/yellowUP.svg';
import iconYellowDown from '../../assets/images/yellowDOWN.svg';
import iconGray from '../../assets/images/gray.svg';

type Size = 12 | 16 | 24;

export type InsulinTrendIconProps = {
  trend: MeasurementTrend;
  size?: Size;
};

const widthMap: Record<Size, string> = {
  12: '20px',
  16: '26px',
  24: '40px',
};

const heightMap: Record<Size, string> = {
  12: '20px',
  16: '26px',
  24: '40px',
};

const getRoundedTrend = (trend: number) => {
  if (trend >= 3) return 3;
  if (trend >= 2 && trend < 3) return 2;
  if (trend >= 1 && trend < 2) return 1;
  if (Math.abs(trend) < 1) return 0;
  if (trend <= -2 && trend > -3) return -2;
  if (trend <= -1 && trend > -2) return -1;
  if (trend <= -3) return -3;
};

export const InsulinTrendIcon = ({trend, size = 24}: InsulinTrendIconProps): JSX.Element => {
  const roundedTrend = getRoundedTrend(trend) as keyof typeof iconMap;
  const iconMap: Record<MeasurementTrend, string> = {
    '3': iconRedUp,
    '2': iconOrangeUp,
    '1': iconYellowUp,
    '0': iconGray,
    '-1': iconYellowDown,
    '-2': iconOrangeDown,
    '-3': iconRedDown,
  };
  const selectedIcon = iconMap[roundedTrend] || iconGray;

  return (
    <StyledContainer width={widthMap[size]} height={heightMap[size]} background="transparent">
      <img src={selectedIcon} style={{width: '100%', height: 'auto'}} loading="lazy" />
    </StyledContainer>
  );
};
