import {BasalProfile, DynamicData} from '../../../api';
import {useRef, useEffect, useState} from 'react';
import {scaleLinear, scaleTime} from '@visx/scale';
import {AxisBottom, AxisLeft} from '@visx/axis';
import {Group} from '@visx/group';
import GridColumns from '@visx/grid/lib/grids/GridColumns';
import GridRows from '@visx/grid/lib/grids/GridRows';
import {curveStepAfter, line} from 'd3-shape';
import {timeSampConvert} from '../utils';
import {FormControlLabel, Grid, Switch} from '@mui/material';
import styled from '@emotion/styled';
import {Circle} from '@visx/shape';
import moment from 'moment';

interface DataPoint {
  x: Date;
  y: number;
}

interface InsulinAndMealsChartProps {
  microboluses: DynamicData['microboluses'];
  crosshairTime: number | null;
  setCrosshairTime: (time: number | null) => void;
  basalProfiles: BasalProfile[];
}

export const InsulinAndMealsChart = ({
  microboluses,
  crosshairTime,
  setCrosshairTime,
  basalProfiles,
}: InsulinAndMealsChartProps): JSX.Element => {
  const [tooltipData, setTooltipData] = useState<DataPoint | null>(null);
  const [tooltipPosition, setTooltipPosition] = useState<{x: number; y: number} | null>(null);
  const [width, setWidth] = useState(1000);
  const [isLineChart, setIsLineChart] = useState(true);
  const [tooltipTitle, setTooltipTitle] = useState<string>('');

  const microbolusesData: DataPoint[] = microboluses.map(micro => ({
    x: new Date(timeSampConvert(micro.eventUTCInstant)),
    y: micro.basalSafetyNetAmount,
  }));

  const svgRef = useRef<SVGSVGElement>(null);
  const minX = Math.min(...microbolusesData.map(d => d.x.getTime()));
  const maxX = Math.max(...microbolusesData.map(d => d.x.getTime()));

  const generateRepeatedBasalProfileData = () => {
    const repeatedData: DataPoint[] = [];

    for (let time = minX; time <= maxX; time += 60 * 60 * 1000) {
      const hourIndex = ((time - minX) / (60 * 60 * 1000)) % 24;
      const value = parseFloat(basalProfiles[0].profile[Math.floor(hourIndex)].toString());
      repeatedData.push({x: new Date(time), y: value});
    }

    const finalHourIndex = ((maxX - minX) / (60 * 60 * 1000)) % 24;
    const finalValue = parseFloat(basalProfiles[0].profile[Math.floor(finalHourIndex)].toString());
    repeatedData.push({x: new Date(maxX), y: finalValue});

    return repeatedData;
  };

  const basalProfileData = generateRepeatedBasalProfileData();

  const minY = 0;
  const maxY = Math.max(...microbolusesData.map(d => d.y), ...basalProfileData.map(d => d.y));
  const height = 250;
  const margin = {top: 20, right: 20, bottom: 30, left: 40};

  useEffect(() => {
    if (svgRef.current) {
      setWidth(svgRef.current.clientWidth);
    }

    const handleResize = () => {
      if (svgRef.current) {
        setWidth(svgRef.current.clientWidth);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const xScale = scaleTime({
    domain: [minX, maxX],
    range: [margin.left, width - margin.right],
  });

  const yScale = scaleLinear({
    domain: [minY, maxY],
    range: [height - margin.bottom, margin.top],
  });

  const lineGenerator = line<DataPoint>()
    .x(d => xScale(d.x))
    .y(d => yScale(d.y))
    .curve(curveStepAfter);

  const handleMouseMove = (event: React.MouseEvent<SVGSVGElement>) => {
    const svgRect = svgRef.current?.getBoundingClientRect();
    if (!svgRect) return;

    const mouseXPosition = event.clientX - svgRect.left;
    const timeAtMousePosition = xScale.invert(mouseXPosition).getTime();

    const closestMicrobolus = microbolusesData.reduce((prev, curr) => {
      const prevDistance = Math.abs(xScale(prev.x) - mouseXPosition);
      const currDistance = Math.abs(xScale(curr.x) - mouseXPosition);
      return currDistance < prevDistance ? curr : prev;
    });

    const closestBasalProfile = basalProfileData.reduce((prev, curr) => {
      const prevDistance = Math.abs(xScale(prev.x) - mouseXPosition);
      const currDistance = Math.abs(xScale(curr.x) - mouseXPosition);
      return currDistance < prevDistance ? curr : prev;
    });

    const closestDatum =
      Math.abs(xScale(closestMicrobolus.x) - mouseXPosition) < Math.abs(xScale(closestBasalProfile.x) - mouseXPosition)
        ? closestMicrobolus
        : closestBasalProfile;

    const tooltipTitle = closestDatum === closestMicrobolus ? 'Microbolus' : 'Basal';
    setTooltipTitle(tooltipTitle);

    setCrosshairTime(timeAtMousePosition);

    setTooltipData(closestDatum);
    setTooltipPosition({
      x: xScale(closestDatum.x),
      y: yScale(closestDatum.y),
    });
  };

  const handleMouseLeave = () => {
    setTooltipData(null);
    setTooltipPosition(null);
    setCrosshairTime(null);
  };

  const ChartLabel = styled('h5')(() => ({
    display: 'flex',
    alignItems: 'center',
  }));

  return (
    <div>
      <Grid container xs={12} spacing={2} mt={2}>
        <Grid xs={0.5} display={'flex'} padding={2}>
          <ChartLabel>U/h</ChartLabel>
        </Grid>
        <Grid xs={11.5}>
          <FormControlLabel
            control={<Switch checked={isLineChart} onChange={() => setIsLineChart(!isLineChart)} />}
            label={isLineChart ? 'Microbolus Line' : 'Microbolus Dots'}
          />
          <div style={{position: 'relative'}}>
            <svg
              ref={svgRef}
              width="100%"
              height={height}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}>
              <defs>
                <linearGradient
                  id="microbolus-gradient"
                  x1="0"
                  y1="0"
                  x2="1"
                  y2="0"
                  spreadMethod="pad"
                  gradientTransform={`translate(${margin.left}, 0) scale(${width - margin.left - margin.right}, 1)`}>
                  <stop offset="0%" stopColor="#03D4FD" />
                  <stop offset="100%" stopColor="#0AD3CD" />
                </linearGradient>
              </defs>

              <rect width={width} height={height} rx={14} fill={'#fff'} />

              <Group>
                {/* Grid */}
                <GridColumns
                  scale={xScale}
                  height={height - margin.bottom}
                  stroke="#e0e0e0"
                  strokeDasharray="5,5"
                  pointerEvents="none"
                />
                <GridRows scale={yScale} width={width - margin.right} strokeDasharray="4" top={margin.top} />
                <AxisBottom
                  top={height - margin.bottom}
                  scale={xScale}
                  tickLabelProps={() => ({
                    fill: 'black',
                    fontSize: 11,
                    textAnchor: 'middle',
                  })}
                  tickFormat={d => {
                    const date = d instanceof Date ? d : new Date(d.valueOf());
                    return date.toLocaleTimeString('en-EU', {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: false,
                    });
                  }}
                />
                <AxisLeft
                  scale={yScale}
                  left={margin.left}
                  tickLabelProps={() => ({
                    fill: 'black',
                    fontSize: 11,
                    textAnchor: 'end',
                  })}
                />
              </Group>
              {isLineChart ? (
                <path
                  d={lineGenerator(microbolusesData) || undefined}
                  fill="none"
                  stroke="url(#microbolus-gradient)"
                  strokeWidth={2}
                />
              ) : (
                microbolusesData.map((d, i) => (
                  <Circle key={`microbolus-dot-${i}`} cx={xScale(d.x)} cy={yScale(d.y)} r={2} fill="#03D4FD" />
                ))
              )}
              <path d={lineGenerator(basalProfileData) || undefined} fill="none" stroke="#8B8C89" strokeWidth={2} />

              {/* Crosshair */}
              {crosshairTime !== null && (
                <line
                  x1={xScale(new Date(crosshairTime))}
                  x2={xScale(new Date(crosshairTime))}
                  y1={margin.top}
                  y2={height - margin.bottom}
                  stroke="gray"
                  strokeDasharray="4,4"
                />
              )}

              {tooltipPosition && <Circle cx={tooltipPosition.x} cy={tooltipPosition.y} r={3} fill="black" />}
            </svg>

            {/* Tooltip */}
            {tooltipData && tooltipPosition && (
              <div
                style={{
                  position: 'absolute',
                  top: tooltipPosition.y - 120,
                  left: tooltipPosition.x - 100,
                  backgroundColor: '#fff',
                  padding: '5px 10px',
                  borderRadius: '5px',
                  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
                  justifyContent: 'center',
                  textAlign: 'center',
                  zIndex: 9999,
                  pointerEvents: 'none',
                }}>
                <h5>{tooltipTitle}</h5>
                <p style={{fontWeight: 'bold', fontSize: '16px'}}>{`${tooltipData.y.toFixed(2)} U`}</p>
                <p style={{fontSize: '14px'}}>{`${moment(tooltipData.x).format('DD/MM/YYYY HH:mm:ss')}`}</p>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
